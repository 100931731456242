exports.components = {
  "component---src-case-studies-3-m-coderyte-mdx": () => import("./../../../src/case-studies/3m-coderyte.mdx" /* webpackChunkName: "component---src-case-studies-3-m-coderyte-mdx" */),
  "component---src-case-studies-ahrq-cds-mdx": () => import("./../../../src/case-studies/ahrq-cds.mdx" /* webpackChunkName: "component---src-case-studies-ahrq-cds-mdx" */),
  "component---src-case-studies-all-of-us-mdx": () => import("./../../../src/case-studies/all-of-us.mdx" /* webpackChunkName: "component---src-case-studies-all-of-us-mdx" */),
  "component---src-case-studies-care-cards-mdx": () => import("./../../../src/case-studies/care-cards.mdx" /* webpackChunkName: "component---src-case-studies-care-cards-mdx" */),
  "component---src-case-studies-commonhealth-smart-health-cards-mdx": () => import("./../../../src/case-studies/commonhealth-smart-health-cards.mdx" /* webpackChunkName: "component---src-case-studies-commonhealth-smart-health-cards-mdx" */),
  "component---src-case-studies-fastercures-health-data-basics-mdx": () => import("./../../../src/case-studies/fastercures-health-data-basics.mdx" /* webpackChunkName: "component---src-case-studies-fastercures-health-data-basics-mdx" */),
  "component---src-case-studies-hgraph-mdx": () => import("./../../../src/case-studies/hgraph.mdx" /* webpackChunkName: "component---src-case-studies-hgraph-mdx" */),
  "component---src-case-studies-infobionic-heart-monitoring-mdx": () => import("./../../../src/case-studies/infobionic-heart-monitoring.mdx" /* webpackChunkName: "component---src-case-studies-infobionic-heart-monitoring-mdx" */),
  "component---src-case-studies-insidetracker-nutrition-science-mdx": () => import("./../../../src/case-studies/insidetracker-nutrition-science.mdx" /* webpackChunkName: "component---src-case-studies-insidetracker-nutrition-science-mdx" */),
  "component---src-case-studies-inspired-ehrs-mdx": () => import("./../../../src/case-studies/inspired-ehrs.mdx" /* webpackChunkName: "component---src-case-studies-inspired-ehrs-mdx" */),
  "component---src-case-studies-mass-snap-mdx": () => import("./../../../src/case-studies/mass-snap.mdx" /* webpackChunkName: "component---src-case-studies-mass-snap-mdx" */),
  "component---src-case-studies-maya-ehr-mdx": () => import("./../../../src/case-studies/maya-ehr.mdx" /* webpackChunkName: "component---src-case-studies-maya-ehr-mdx" */),
  "component---src-case-studies-mitre-flux-notes-mdx": () => import("./../../../src/case-studies/mitre-flux-notes.mdx" /* webpackChunkName: "component---src-case-studies-mitre-flux-notes-mdx" */),
  "component---src-case-studies-mitre-shr-mdx": () => import("./../../../src/case-studies/mitre-shr.mdx" /* webpackChunkName: "component---src-case-studies-mitre-shr-mdx" */),
  "component---src-case-studies-mitre-state-of-us-healthcare-mdx": () => import("./../../../src/case-studies/mitre-state-of-us-healthcare.mdx" /* webpackChunkName: "component---src-case-studies-mitre-state-of-us-healthcare-mdx" */),
  "component---src-case-studies-mount-sinai-consent-mdx": () => import("./../../../src/case-studies/mount-sinai-consent.mdx" /* webpackChunkName: "component---src-case-studies-mount-sinai-consent-mdx" */),
  "component---src-case-studies-paintrackr-mdx": () => import("./../../../src/case-studies/paintrackr.mdx" /* webpackChunkName: "component---src-case-studies-paintrackr-mdx" */),
  "component---src-case-studies-partners-geneinsight-mdx": () => import("./../../../src/case-studies/partners-geneinsight.mdx" /* webpackChunkName: "component---src-case-studies-partners-geneinsight-mdx" */),
  "component---src-case-studies-partners-insight-mdx": () => import("./../../../src/case-studies/partners-insight.mdx" /* webpackChunkName: "component---src-case-studies-partners-insight-mdx" */),
  "component---src-case-studies-personal-genome-project-vision-mdx": () => import("./../../../src/case-studies/personal-genome-project-vision.mdx" /* webpackChunkName: "component---src-case-studies-personal-genome-project-vision-mdx" */),
  "component---src-case-studies-tabeeb-diagnostics-mdx": () => import("./../../../src/case-studies/tabeeb-diagnostics.mdx" /* webpackChunkName: "component---src-case-studies-tabeeb-diagnostics-mdx" */),
  "component---src-case-studies-wuxi-nextcode-familycode-mdx": () => import("./../../../src/case-studies/wuxi-nextcode-familycode.mdx" /* webpackChunkName: "component---src-case-studies-wuxi-nextcode-familycode-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-3-m-coderyte-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/3m-coderyte.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-3-m-coderyte-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-ahrq-cds-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/ahrq-cds.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-ahrq-cds-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-all-of-us-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/all-of-us.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-all-of-us-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-care-cards-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/care-cards.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-care-cards-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-commonhealth-smart-health-cards-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/commonhealth-smart-health-cards.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-commonhealth-smart-health-cards-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-fastercures-health-data-basics-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/fastercures-health-data-basics.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-fastercures-health-data-basics-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-hgraph-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/hgraph.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-hgraph-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-infobionic-heart-monitoring-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/infobionic-heart-monitoring.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-infobionic-heart-monitoring-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-insidetracker-nutrition-science-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/insidetracker-nutrition-science.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-insidetracker-nutrition-science-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-inspired-ehrs-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/inspired-ehrs.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-inspired-ehrs-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mass-snap-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/mass-snap.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mass-snap-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-maya-ehr-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/maya-ehr.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-maya-ehr-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mitre-flux-notes-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/mitre-flux-notes.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mitre-flux-notes-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mitre-shr-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/mitre-shr.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mitre-shr-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mitre-state-of-us-healthcare-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/mitre-state-of-us-healthcare.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mitre-state-of-us-healthcare-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mount-sinai-consent-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/mount-sinai-consent.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-mount-sinai-consent-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-paintrackr-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/paintrackr.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-paintrackr-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-partners-geneinsight-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/partners-geneinsight.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-partners-geneinsight-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-partners-insight-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/partners-insight.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-partners-insight-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-personal-genome-project-vision-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/personal-genome-project-vision.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-personal-genome-project-vision-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-tabeeb-diagnostics-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/tabeeb-diagnostics.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-tabeeb-diagnostics-mdx" */),
  "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-wuxi-nextcode-familycode-mdx": () => import("./../../../src/components/layouts/case-study-layout.js?__contentFilePath=/Users/craigmcginley/code/work/goinvo.com/src/case-studies/wuxi-nextcode-familycode.mdx" /* webpackChunkName: "component---src-components-layouts-case-study-layout-js-content-file-path-src-case-studies-wuxi-nextcode-familycode-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-careers-js": () => import("./../../../src/pages/about/careers.js" /* webpackChunkName: "component---src-pages-about-careers-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-open-office-hours-js": () => import("./../../../src/pages/about/open-office-hours.js" /* webpackChunkName: "component---src-pages-about-open-office-hours-js" */),
  "component---src-pages-about-studio-timeline-js": () => import("./../../../src/pages/about/studio-timeline.js" /* webpackChunkName: "component---src-pages-about-studio-timeline-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-source-health-design-js": () => import("./../../../src/pages/open-source-health-design.js" /* webpackChunkName: "component---src-pages-open-source-health-design-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-vision-augmented-clinical-decision-support-index-js": () => import("./../../../src/pages/vision/augmented-clinical-decision-support/index.js" /* webpackChunkName: "component---src-pages-vision-augmented-clinical-decision-support-index-js" */),
  "component---src-pages-vision-coronavirus-index-js": () => import("./../../../src/pages/vision/coronavirus/index.js" /* webpackChunkName: "component---src-pages-vision-coronavirus-index-js" */),
  "component---src-pages-vision-determinants-of-health-index-js": () => import("./../../../src/pages/vision/determinants-of-health/index.js" /* webpackChunkName: "component---src-pages-vision-determinants-of-health-index-js" */),
  "component---src-pages-vision-digital-health-trends-2022-index-js": () => import("./../../../src/pages/vision/digital-health-trends-2022/index.js" /* webpackChunkName: "component---src-pages-vision-digital-health-trends-2022-index-js" */),
  "component---src-pages-vision-faces-in-health-communication-index-js": () => import("./../../../src/pages/vision/faces-in-health-communication/index.js" /* webpackChunkName: "component---src-pages-vision-faces-in-health-communication-index-js" */),
  "component---src-pages-vision-fraud-waste-abuse-in-healthcare-index-js": () => import("./../../../src/pages/vision/fraud-waste-abuse-in-healthcare/index.js" /* webpackChunkName: "component---src-pages-vision-fraud-waste-abuse-in-healthcare-index-js" */),
  "component---src-pages-vision-health-design-thinking-index-js": () => import("./../../../src/pages/vision/health-design-thinking/index.js" /* webpackChunkName: "component---src-pages-vision-health-design-thinking-index-js" */),
  "component---src-pages-vision-health-visualizations-index-js": () => import("./../../../src/pages/vision/health-visualizations/index.js" /* webpackChunkName: "component---src-pages-vision-health-visualizations-index-js" */),
  "component---src-pages-vision-healthcare-ai-index-js": () => import("./../../../src/pages/vision/healthcare-ai/index.js" /* webpackChunkName: "component---src-pages-vision-healthcare-ai-index-js" */),
  "component---src-pages-vision-healthcare-dollars-index-js": () => import("./../../../src/pages/vision/healthcare-dollars/index.js" /* webpackChunkName: "component---src-pages-vision-healthcare-dollars-index-js" */),
  "component---src-pages-vision-history-of-health-design-index-js": () => import("./../../../src/pages/vision/history-of-health-design/index.js" /* webpackChunkName: "component---src-pages-vision-history-of-health-design-index-js" */),
  "component---src-pages-vision-index-js": () => import("./../../../src/pages/vision/index.js" /* webpackChunkName: "component---src-pages-vision-index-js" */),
  "component---src-pages-vision-living-health-lab-index-js": () => import("./../../../src/pages/vision/living-health-lab/index.js" /* webpackChunkName: "component---src-pages-vision-living-health-lab-index-js" */),
  "component---src-pages-vision-loneliness-in-our-human-code-index-js": () => import("./../../../src/pages/vision/loneliness-in-our-human-code/index.js" /* webpackChunkName: "component---src-pages-vision-loneliness-in-our-human-code-index-js" */),
  "component---src-pages-vision-national-cancer-navigation-index-js": () => import("./../../../src/pages/vision/national-cancer-navigation/index.js" /* webpackChunkName: "component---src-pages-vision-national-cancer-navigation-index-js" */),
  "component---src-pages-vision-open-pro-index-js": () => import("./../../../src/pages/vision/open-pro/index.js" /* webpackChunkName: "component---src-pages-vision-open-pro-index-js" */),
  "component---src-pages-vision-open-source-healthcare-index-js": () => import("./../../../src/pages/vision/open-source-healthcare/index.js" /* webpackChunkName: "component---src-pages-vision-open-source-healthcare-index-js" */),
  "component---src-pages-vision-own-your-health-data-index-js": () => import("./../../../src/pages/vision/own-your-health-data/index.js" /* webpackChunkName: "component---src-pages-vision-own-your-health-data-index-js" */),
  "component---src-pages-vision-patient-centered-consent-index-js": () => import("./../../../src/pages/vision/patient-centered-consent/index.js" /* webpackChunkName: "component---src-pages-vision-patient-centered-consent-index-js" */),
  "component---src-pages-vision-physician-burnout-index-js": () => import("./../../../src/pages/vision/physician-burnout/index.js" /* webpackChunkName: "component---src-pages-vision-physician-burnout-index-js" */),
  "component---src-pages-vision-precision-autism-index-js": () => import("./../../../src/pages/vision/precision-autism/index.js" /* webpackChunkName: "component---src-pages-vision-precision-autism-index-js" */),
  "component---src-pages-vision-primary-self-care-algorithms-index-js": () => import("./../../../src/pages/vision/primary-self-care-algorithms/index.js" /* webpackChunkName: "component---src-pages-vision-primary-self-care-algorithms-index-js" */),
  "component---src-pages-vision-public-healthroom-index-js": () => import("./../../../src/pages/vision/public-healthroom/index.js" /* webpackChunkName: "component---src-pages-vision-public-healthroom-index-js" */),
  "component---src-pages-vision-test-treat-trace-index-js": () => import("./../../../src/pages/vision/test-treat-trace/index.js" /* webpackChunkName: "component---src-pages-vision-test-treat-trace-index-js" */),
  "component---src-pages-vision-us-healthcare-problems-index-js": () => import("./../../../src/pages/vision/us-healthcare-problems/index.js" /* webpackChunkName: "component---src-pages-vision-us-healthcare-problems-index-js" */),
  "component---src-pages-vision-vapepocolypse-index-js": () => import("./../../../src/pages/vision/vapepocolypse/index.js" /* webpackChunkName: "component---src-pages-vision-vapepocolypse-index-js" */),
  "component---src-pages-vision-virtual-care-index-js": () => import("./../../../src/pages/vision/virtual-care/index.js" /* webpackChunkName: "component---src-pages-vision-virtual-care-index-js" */),
  "component---src-pages-vision-virtual-diabetes-care-index-js": () => import("./../../../src/pages/vision/virtual-diabetes-care/index.js" /* webpackChunkName: "component---src-pages-vision-virtual-diabetes-care-index-js" */),
  "component---src-pages-vision-who-uses-my-health-data-index-js": () => import("./../../../src/pages/vision/who-uses-my-health-data/index.js" /* webpackChunkName: "component---src-pages-vision-who-uses-my-health-data-index-js" */),
  "component---src-pages-why-hire-healthcare-design-studio-js": () => import("./../../../src/pages/why-hire-healthcare-design-studio.js" /* webpackChunkName: "component---src-pages-why-hire-healthcare-design-studio-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

